@import 'styles/mixins.scss';

.maintainNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: #FFF7E8;
  margin: 0 auto;
  padding: 20px 115px;

  @include sm {
    padding: 20px 20px;
  }

  .content {
    margin: 0;
    text-align: center;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin-right: 4px;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  .text {
    font-size: 14px;
    color: #D25F00;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
  }

  .refresh {
    margin: 0 0 0 4px;
    color: #D25F00;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    padding: 0;
  }
}