.main {
  padding: 12px 8px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 8px;
}

.content {
  display: flex;
  color: #121212;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 24px 0;
}

.footer {
  display: flex;
  justify-content: center;
}

.button {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  display: flex;
  height: 36px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.cancel {
  color: #121212;
  border: 1px solid #e4e4e4;
  background: transparent;
}

.confirm {
  margin-left: 8px;
  background: #2378e5;
  color: #fff;
}
