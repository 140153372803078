@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.container {
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  flex-direction: column;
  background: linear-gradient(45deg, #9f91f9 0%, #5860e0 36.51%, #5860e0 87.97%);

  >div {
    flex: 1;
  }

  >span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  >.button {
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 15px;
    height: 72px;
    font-size: 20px;

    &:hover {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.1);
    }
  }
}