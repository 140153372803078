.messageDrawerContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  // margin-right: 30px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  // overflow: hidden;
  // padding-right: 40px;
}
.messageDrawerContent {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  width: 390px;
  background: #fff;
  flex: 1;
  z-index: 1;
  padding-bottom: 20px;
  overflow: hidden;
  // margin-right: 30px;
  border-radius: 8px;

  height: 100%;

  .drawerHeader {
    display: flex;
    padding: 28px 16px 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--main-color-black-black-01121212, #121212);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.408px;
  }
  .readAll {
    color: var(--neutral-color-text-05515151, #515151);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.408px;
    text-decoration-line: underline;
    cursor: pointer;
    &:hover {
      color: var(--neutral-color-text-06121212, #121212);
    }

    &[data-disabled="true"] {
      color: #b8b8b8;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .messageWrapper {
    flex: 1;
    overflow: auto;
  }
}

.messageItem {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:not(:last-child) {
    border-bottom: 4px solid #f6f6f6;
  }
  .header {
    color: var(--black-500-b-8-b-8-b-8, #b8b8b8);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .titleWrapper {
    display: flex;
    gap: 4px;
  }
  .title {
    color: var(--black-800121212, #121212);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
  }
  .content {
    color: var(--black-6007-d-7-d-7-d, #7d7d7d);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.visibleAll {
      display: block;
    }
    // why add this?
    // in richtext, there are some html tags in those text, and it's style have been override by global css
    // so should add margin for each element
    > * {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .dot {
    background-color: #fe4a4b;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    flex-shrink: 0;
  }
  .img {
    max-width: 100%;
    max-height: 150px;
  }
  .actionArea {
    margin-top: 4px;
  }
  .seeMore {
    color: var(--brand-color-052378-e-5, #2378e5);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      color: #1b66c9;
    }
  }
}

.antTabWrapper {
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        padding: 0 16px;
        margin: 0px;
      }

      .ant-tabs-nav-list {
        gap: 20px;
      }
      .ant-tabs-tab {
        margin: 0;
        color: var(--neutral-color-text-05515151, #515151);
        font-size: 14px;
        font-weight: 500 !important;
        line-height: normal;
        height: 45px;
      }
      .ant-tabs-tab-active {
        color: #2378e5;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #2378e5;
        font-weight: 500;
      }
      .ant-tabs-ink-bar {
        background: #2378e5 !important;
      }
    }
  }
}

.noMoreText {
  padding: 16px 24px;
  text-align: center;
  color: var(--black-500-b-8-b-8-b-8, #b8b8b8);
  font-size: 15px;
  font-weight: 400;
}
