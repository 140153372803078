@import 'styles/variable.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: .6;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: .6;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}



.maskshow {
  visibility: visible;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .6;
  background-color: rgba(0, 0, 0);
  animation: fadeIn 200ms linear forwards;
}

.maskhide {
  visibility: hidden;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0);
  animation: fadeOut 200ms linear forwards;
}

@keyframes slideIn {
  from {
    right: -100%;
    visibility: hidden;
  }

  to {
    right: 0;
    visibility: visible;
  }
}

@keyframes slideOut {
  from {
    right: 0;
    visibility: visible;
  }

  to {
    right: -100%;
    visibility: hidden;
  }
}

.mobileFullPageMenu {
  visibility: hidden;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 70vw;
  min-width: 180px;
  z-index: 1001;
  right: -100%;
  background-color: #fff;
}

.mobileFullPageMenu.open {
  animation: slideIn 200ms linear forwards
}

.mobileFullPageMenu.close {
  animation: slideOut 200ms linear forwards;
}


.closeIcon {
  margin-right: 18px;

  span {
    display: block;
    width: 12px;
    height: 2px;
    margin-bottom: 3px;
    position: relative;

    background: #000;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) 0.3s,
      background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) 0.3s,
      opacity 0.55s ease 0.3s;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }


}

.active {

  span {
    opacity: 1;
    transform: rotate(-45deg) translate(2px, -1px);
  }

  span:nth-last-child(3) {
    transform: rotate(45deg) translate(4px, -1px);
  }

  span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
}

.menuListWrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    list-style: none;
    margin: 0;
    padding: 14px 0;
  }

  span {
    font-size: 14px
  }
}

.menuList {
  display: block;
  padding: 0 0 10px;

  // &:hover {
  //   // color: $primaryBlue;
  //   // font-weight: bold;
  // }


}

.downloadApp {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  height: 22px;
  box-sizing: content-box;
  background-color: #121212;
  color: #fff;
  font-size: 14px;

  span {
    margin-right: 8px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

.defaultLink {
  display: block;
  box-sizing: border-box;
  padding: 0 24px;
  font-size: 14px;
  height: 48px;
  color: #000 !important;

  &:first-child {
    border-top: none;
  }

  &:hover {
    cursor: pointer;
    color: $primaryBlue;
  }
}

.divider {
  border-top: 3px solid #f0f0f0;
}

.activeLink {
  width: auto;
  color: $primaryBlue !important;
  font-weight: bold;
}

.hotTag {
  display: flex;
  top: 2px;
  right: -49px;
  background: #d2030f;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  line-height: 15px;
  font-weight: 400;
  padding: 1px 5px;
  margin: 0 10px;
}

.unCompleted::after {
  content: ' ';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f44336;
  margin-left: 4px;
  display: inline-block;
  margin-bottom: 1px;
}

.findJobMenu {
  padding: 0px;
}