.main {
  display: flex;
  width: 100%;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #edf5ff;
  position: relative;

  margin-bottom: 16px;
}

.left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  display: flex;
  width: 60px;
  height: 56px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.title {
  color: #121212;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.34px;
}

.description {
  color: #7d7d7d;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.right {
  flex-shrink: 0;
  margin-left: 20px;
  max-width: 220px;
}

.button {
  display: flex;
  min-width: 80px;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 34px;
  background: #2378e5;
  border: 0;
  outline: none;
  flex-shrink: 0;
  box-shadow: none;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.arrow {
  flex-shrink: 0;
}

.close {
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.mobile {
  display: flex;
  padding: 20px 24px;
  border-radius: 8px;
  background: #edf5ff;
  position: relative;
  flex-direction: column;
  margin: 0 10px 8px 10px;
}

.mobile_content {
  padding-right: 30px;
}

.mobile_description {
  font-size: 12px;
}

.mobile_icon {
  width: 91px;
  height: 84px;
  flex-shrink: 0;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.mobile_button {
  margin-top: 16px;
  max-width: 220px;
}
