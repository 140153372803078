.modal_wrapper {
  & > div {
    width: 728px !important;
  }

  :global {
    .ant-modal-content {
      padding: 36px 32px;
    }

    .ant-modal-close-x {
      height: 16px !important;
      width: 32px !important;
    }
  }

  .middleGap {
    height: 40px;
  }
}

.labelWrapper,
.antSelectWrapper {
  // implement below
  opacity: 1;

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: inherit;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      height: 30px;
    }

    .ant-select .ant-select-arrow {
      opacity: 1;

      .ant-select-selector {


        .ant-select-selection-item {}
      }


    }
  }
}

@media screen and (min-width: 600px) {
  // .modalWrapper {
  //   & > div {
  //     width: calc(100vw - 32px) !important;
  //   }
  // }
  .antSelectWrapper {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .modalWrapper {
    & > div {
      width: calc(100vw - 32px) !important;
    }

    :global {
      .ant-modal-content {
        padding: 36px 20px;
      }
    }

    .middleGap {
      height: 24px;
    }
  }

  .labelWrapper {
    display: none !important;
  }
}
