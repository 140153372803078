@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.modalReportJob {
  height: 305px;

  &Item {
    padding: 0 10px;
    position: relative;

    span {
      display: block;
      padding: 20px 0;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($lightgrey, 0.2);
    }

    &:not(:last-child) {
      span {
        border-bottom: 1px solid rgba($lightgrey, 0.5);
      }
    }
  }

  &ItemIcon {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
}

.modalReportJobDetail {
  &Item {
    &:not(:last-child) {
      .modalReportJobDetailRadioGroup {
        border-bottom: 1px solid rgba($lightgrey, 0.5);
      }
    }
  }

  &Label {
    padding: 20px 0;
    margin-left: 6px;

    span {
      display: block;
      line-height: 1.5;
    }
  }
}