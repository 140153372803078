@import 'styles/variable';
@import 'styles/mixins';

.footer {
  position: relative;
  display: flex;
  color: #86909c;
  background: #050317;
  // border-radius: 24px 24px 0 0;

  @include md {
    min-width: calc($maxContentWidth);
  }

  &Container {
    box-sizing: border-box;
    width: 100%;
    padding: 60px 0 0;

    p {
      color: #E4E4E4;
    }

    @include msd {
      padding: 0;
    }
  }

  &MobileContainer {
    padding: 0 20px;
    padding-bottom: 20px;

    @include md {
      display: none;
    }

    @include msd {
      padding: 0;
    }
  }

  &Wrapper {
    @include flex;

    margin-top: 25px;
    margin-right: 15%;
    margin-left: 15%;
    color: $black;
  }

  &Link {
    cursor: pointer;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: #B8B8B8;
      letter-spacing: 0.105px;

      &:hover {
        color: #fff;
      }
    }
  }

  &Links {
    margin-top: 40px;
  }
}

/* Desktop */
.footerDesktop {
  &App {
    display: flex;
    height: 42px;

    a {
      width: 140px;
      height: 100%;
      margin-right: 20px;
    }
  }

  &LinkTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
  }

  &DividerLine {
    width: 100%;
    height: 1px;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &Wrapper {
    display: none;

    @include md {
      display: block;
    }
  }

  &Top {
    @extend .GlobalContainer;

    @include md {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: $maxContentWidth;
    }
  }

  &Left {
    width: 550px;
    margin-right: 60px;

    @include md {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &LeftTop {}

  &Logo {
    width: 124px;
    height: 32px;
    object-fit: cover;
  }

  &LeftBottom {
    margin-top: 75px;
  }

  &Company {
    margin-top: 32px;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #B8B8B8;
    }
  }

  &CompanyDesc {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.60);
    letter-spacing: 0.105px;
  }

  &Right {
    width: 572px;

    @include md {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &LinkList {
    margin-top: 20px;
    margin-bottom: 0;
    list-style: none;
    -webkit-padding-start: 0;

    &>li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include msd {
      margin-top: 0;

      &>li {
        margin-bottom: 32px;
      }
    }
  }

  &Bottom {
    @extend .GlobalContainer;

    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    @include md {
      min-width: $maxContentWidth;
    }
  }

  &SocialWrapper {
    @include md {
      display: flex;
      align-items: center;
    }
  }

  &SocialDate {
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.60);
    text-align: left;
    letter-spacing: 0.105px;
  }

  &SocialList {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 90px;
    margin-left: -17px;
  }

  &SocialLinks {
    @include md {
      display: flex;
      flex-direction: column;
    }
  }

  &SocialLinkFollow {
    margin-right: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.60);
    letter-spacing: 0.105px;
  }
}

.socialLink {
  margin-left: 16px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.footerMobileSocialLinks {
  margin-top: 30px;
}

.footerDownloadApp {
  @include msd {
    margin-top: 40px;
  }
}

.footerDownloadAppText {
  padding-bottom: 20px;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.60);
  text-transform: capitalize;
  letter-spacing: 0.105px;
}

.footerDesktopAppList {
  font-size: 0;

  a {
    display: inline-block;
    height: 42px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  img {
    object-fit: cover;
  }

  a:nth-child(4),
  a:nth-child(5) {
    margin-bottom: 0;
  }
}

.footerMobileCompany {
  margin-top: 32px;
}

.footerMobileSocialDate {
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.60);
  text-align: left;
  letter-spacing: 0.105px;
}

.footerMobileSocialList {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerMobileSocialLinks {
  display: flex;
  flex-direction: column;
}

.footerMobileSocialLinksText {
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.105px;
}

.footerMobileLinkTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  letter-spacing: 0.12px;
}

.footerCopyrightWrapper {
  @include msd {
    padding-top: 30px;
    padding-bottom: 32px;
  }
}

.copyrightCompanies {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.60);
  letter-spacing: 0.105px;

  span {
    i {
      padding: 0 18px;
      font-style: normal;
    }
  }
}

.footerDesktopLogoLink {
  display: block;
  width: 124px;
  height: 32px;
}