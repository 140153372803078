@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.vipImage {
  position: relative;
  // margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;

  .btn {
    position: absolute;
    top: 33px;
    left: 12px;
    border-radius: 4px;
    height: 23px;
    background: linear-gradient(91deg, #fff4cb 0%, #ffe272 100%);
    text-transform: capitalize;
    box-shadow: none;
    font-size: 12px;
    font-weight: 700;
    color: #346ae3;
  }

  .desc {
    position: absolute;
    left: 12px;
    top: 63px;
    width: 147px;
    line-height: 19px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }

  img.image {
    width: 100%;
    max-width: 100% !important;
    height: auto;
  }
}

.vipShareWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);

  .vipShareModal {
    position: relative;
    width: 1100px;
    //height: 647px;
    // padding: 35px;
    box-sizing: border-box;

    // background-image: url('./vip_share_bg.jpg');
    // background-size: cover;
    .close {
      position: absolute;
      top: 17px;
      right: 15px;
      cursor: pointer;
    }

    h1 {
      line-height: 1;
      font-size: 44px;
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
      margin-top: 23px;
    }

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin: 0;
    }

    .main {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .left {
        width: 50%;
        padding: 75px 35px 75px;
        border-radius: 5px 0 0 5px;

        // padding-right: 47px;
        box-sizing: border-box;
        background-image: url('./vip_left_bg.jpg');
        background-size: 100% 100%;

        .links {
          display: flex;
          padding: 14px 10px;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: 15px;
          font-size: 16px;
          line-height: 23px;
          text-decoration-line: underline;
          border-radius: 4px;
          background: #e7f4fd;
          display: block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          // color: #004aff;

        }

        .blueText {
          margin-bottom: 16px;
          color: #004aff;
          font-family: Product Sans;
          font-style: normal;
          font-size: 44px;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }

        .buttonText {
          margin-bottom: 10px;
          font-size: 28px;
          font-weight: 700;
          color: #fff;
          display: inline-flex;
          padding: 10px 16px;
          align-items: center;
          border-radius: 8px;
          background: #004aff;
        }

        .descText {
          margin-bottom: 80px;
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          text-transform: capitalize;
        }

        .copyButton {
          width: 100%;
          overflow: hidden;
          display: flex;
          padding: 22px 25px;
          margin-top: 15px;
          align-items: center;
          align-self: stretch;
          justify-content: center;
          color: #fff;
          font-size: 16px;
          text-transform: none;
          font-weight: 700;
          height: 67px;
          border-radius: 67.52px;
          background: linear-gradient(270deg, #014bff 2.68%, #458bff 99.89%);
          box-shadow: 0px 4px 13px 0px rgba(1, 76, 255, 0.4);

          .btnText {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .right {
        width: 50%;
        border-radius: 0 5px 5px 0;
        background-image: url('./vip_right_bg.jpg');
        background-size: 100% 100%;

        .desc {
          font-size: 18px;
          font-weight: 700;
        }

        .featureContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: auto;

          p {
            display: flex;
            width: 150px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 20px 20px 30px;
            height: 168px;
            text-align: center;
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.7);
            background: rgba(255, 255, 255, 0.2);

            img {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .vipShareWrapper {
    .vipShareModal {
      max-width: 86vw;
      background-color: #fff;
      border-radius: 14px;
      overflow: hidden;

      .main {
        flex-direction: column-reverse;

        .left {
          width: 100%;
          padding: 0 20px 16px 20px;
          background: #fff;

          .copyButton {
            padding: 0;
            height: 48px;
            width: 100%;
          }

          .descText {
            margin: 0;
            padding: 0 20px;
            color: #121212;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .buttonText {
            color: #121212;
            text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            padding: 16px 0 8px;
            background: none;
            display: flex;
            justify-content: center;
            margin: 0;
          }

          .links {
            width: auto;
            margin-bottom: 20px;
            padding: 12px 10px;
          }
        }

        .right {
          width: 100%;
          height: 64vw;
          border-radius: 8px 8px 0 0;
          background-size: 100%;
        }
      }
    }
  }

}