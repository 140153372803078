@import 'styles/mixins.scss';
@import 'styles/variable.scss';

@mixin containers {
  @extend .GlobalContainer;
  max-width: 1146px; // $maxContentWidth;
  height: 100%;
  display: flex;
}

.logo {
  width: 120px;
  height: 29px;

  @include sm {
    width: 150px;
    height: 40px;
  }
}

.header {
  width: 100%;
  // height: 64px;
  margin-top: 0;
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e6eb;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  @include msd {
    // height: 54px;
    position: sticky;
    top: 0;
    z-index: 30;
  }

  @include sm {
    // height: 54px;
  }
}

.header_h5 {
  line-height: 54px;
}

.headerPosition {
  position: fixed;
  top: 0;
  z-index: 10;
}

.headerContainer {
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;

  @include sm {
    &_defaultH5 {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 1921px) {
  .headerContainer {
    width: 1856px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1920px) {
  .headerContainer {
    padding: 6px 20px;
  }
}

.headerContainer_h5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.unread {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  background: #f44336;
  border-radius: 15px;
  padding: 1px 8px;
  margin-left: 5px;

  @include sm {
    word-break: keep-all;
  }
}

.headerLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    @include sm {
      font-size: 0;
    }
  }

  &Title {
    display: flex;
    align-items: center;
  }

  &Image {
    width: 100px;
    height: 29px;
    object-fit: contain;
  }
}

.headerLinksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 593;
  min-width: none;
  margin-left: 27px;

  @include msd {
    // display: none;
  }
}

.headerLinksList {
  flex-direction: row;
  display: flex;
  height: 100%;
  align-items: center;
  flex-shrink: 0;

  @include sm {
    // display: none;
  }
}

.headerLink {
  padding: 0 12px;
  height: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  letter-spacing: 0.05em;

  @include sm {
    display: none;

    &:last-child {
      display: flex;
    }
  }

  &Item,
  &Icon {
    &:hover {
      cursor: pointer;
    }
  }

  @include sm {
    padding: 0 15px;
  }

  @include lg {
    padding: 0 16px;
  }

  &:last-child {
    padding-right: 0;
  }

  &Text {
    color: #4e5969;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    white-space: nowrap;

    &.isActive {
      color: #2378e5 !important;
      font-weight: 700;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -10px;
      left: 0;
      background-color: #2378e5;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover {
      color: #2378e5 !important;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &TextCurrentPage {
    position: relative;
    color: #1d2129 !important;
    text-align: center;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
  }

  &Login {
    padding-right: 0;
    padding-left: 0;
  }

  &Icon {
    display: flex;

    span {
      margin-left: 8px;
    }
  }

  .countryItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    padding: 0 8px;
    border-radius: 4px;

    &:hover {
      background: #f5f7fb;
    }

    .separator {
      padding: 0 8px;
      color: #e4e4e4;
    }

    .label {
      position: relative;
      z-index: 1;
      color: #4e5969;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.105px;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }

  .countryItemCountry {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #f6f6f6;
  }
}

.headerButton {
  padding: 20px;
}

.headerLinkWrapper {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end !important;

  &Extra {
    cursor: pointer;

    &Text {
      font-size: 11px !important;
      font-weight: normal;
      margin-left: 20px;
      padding-bottom: 2px;
      border-bottom: 1px solid transparent;
      color: $darkgrey;

      &:hover {
        border-bottom: 1px solid $darkgrey;
      }
    }
  }
}

.profile {
  &ProtectedWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;

    .vipAvatar {
      position: relative;
      border: 2px solid #ffc248;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      margin-right: 15px;
    }
  }

  &HasUnread {
    position: relative;

    &::after {
      content: ' ';
      background-color: #f75555;
      width: 6px;
      height: 6px;
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      border-radius: 50%;
    }
  }

  &Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      cursor: not-allowed;
      // cursor: pointer;
    }
  }

  &Country {
    margin-right: 30px;
  }

  &Disabled {
    cursor: none;
  }

  &PlaceHolder {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: inline-block;
    // margin-right: 10px;
    border-radius: 100px;
    border: 3px solid #fff;

    &:hover {
      border-color: var(--neutral-color-line-02-e-8-e-8-ea, #e8e8ea);
    }
  }
}

.mobileIconWrapper {
  display: none;

  .icon {
    margin: 0 0 0 20px;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include msd {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
}

.authUserMenu {
  .headerMenu {
    // position: absolute;
    // top: 0;
    // right: 6px;
    // z-index: 9999;
    // min-width: 150px;
    width: max-content !important;
    background-color: $white-3;
    box-shadow: $box-shadow-1;
    border-radius: 4px;
    overflow: hidden;

    @include msd {
      // display: none;
    }

    &List {
      list-style-type: none;

      li:hover {
        background-color: #f5f5f5;
      }
    }

    &Link {
      padding: 11px 20px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.hotTag {
  position: absolute;
  top: 5px;
  right: -20px;
  background: #d2030f;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  line-height: 15px;
  font-weight: 400;
  padding: 1px 5px;
}

.LandingHeaderContainer {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;
}

.LandingHeader {
  max-width: 1366px;
  margin: 0 auto;

  .headerLogoImage {
    filter: drop-shadow(30px 40px #136fd3);
  }

  .header {
    background: #ffffff;
    border: none;
  }

  .headerLinkText {
    color: #353535;
  }

  .headerLink {
    background: #ffffff;
    color: #136fd3;
  }

  .startButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #136fd3;
    border-radius: 10px;
    min-width: 123px;
    height: 35px;
  }

  .headerLogo {
    .logo {
      padding-top: 3px;
      width: auto;

      a {
        width: auto;
        display: flex;
        align-items: center;
      }
    }
  }

  .countryItem {
    color: #353535;
    display: flex;
    align-items: center;
    gap: 5px;

    .label {
      position: relative;
      z-index: 1;
      background-color: #ffffff;
    }

    .object {
      filter: drop-shadow(#353535 20px 0px);
      transform: translate(-20px, 0px);
    }
  }
}

.unCompleted::after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f44336;
  margin-left: 4px;
  display: inline-block;
  margin-bottom: 1px;
}

.getStartedButton {
  height: 40px !important;
  border: 1px solid #579bf5 !important;
  border-radius: 4px !important;
  padding-left: 23px;
  padding-right: 23px;
  background-color: #ffffff !important;
  min-width: 110px;

  span {
    color: #2378e5;
  }

  &:hover {
    background-color: #2378e5 !important;

    border: 1px solid #579bf5 !important;

    span {
      color: #fff;
    }
  }
}

.headerLinkRightItem {
  padding: 0;
  margin-left: 16px;
}

.locationIcon {
  width: 18px;
  height: 18px;
}

.remoteBackground {
  position: absolute;
  top: 0;
  left: 11px;
  width: 100%;
  height: 100%;
  transform: scale(1.3);
}

.employerAdv {
  width: 576px;
  padding-bottom: 14px;
  border-radius: 8px;
  padding-top: 14px;

  .mainBox {
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 12px;
    padding: 12px 0 12px 12px;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);

    .angle {
      position: absolute;
      left: 50%;
      top: -11px;
      transform: translateX(-50%);
    }

    > div {
      display: flex;
      width: 176px;
      max-height: 199px;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      padding-bottom: 4px;
      border: 1px solid #fff;
      cursor: pointer;

      a {
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .svg {
        padding-top: 26px;
        transform: scale(1);
        transition: 0.3s;
      }

      &.efficiency {
        background: linear-gradient(180deg, #ffe4a6 0%, #fffdfc 54.33%);

        &:hover {
          border: 1px solid #ffb201;

          h5 {
            color: #2378e5;
          }

          .svg {
            transform: scale(1.2);
            transition: 0.3s;
          }
        }
      }

      &.accuracy {
        background: linear-gradient(180deg, #bde5ff 0%, #fffdfc 54.33%);

        &:hover {
          border: 1px solid #3272f9;

          h5 {
            color: #2378e5;
          }

          .svg {
            transform: scale(1.2);
            transition: 0.3s;
          }
        }
      }

      &.quality {
        background: linear-gradient(180deg, #bef997 0%, #fffdfc 54.33%);

        &:hover {
          border: 1px solid #6dcf69;

          h5 {
            color: #2378e5;
          }

          .svg {
            transform: scale(1.2);
            transition: 0.3s;
          }
        }
      }

      h5 {
        color: #121212;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding: 20px 0 0;
        margin: 0;
        line-height: 24.848px;
      }

      p {
        color: #7d7d7d;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.742px;
        padding: 0 8px;
      }
    }
  }
}

.startedAdv {
  min-width: 280px;
  border-radius: 8px;
  padding-top: 4px;

  .mainBox {
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 20px 8px;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);

    .angle {
      position: absolute;
      left: 50%;
      top: -11px;
      transform: translateX(-50%);
    }

    h5 {
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-bottom: 18px;
    }

    .item {
      padding: 12px 0;
      display: flex;
      border-bottom: 1px solid #f6f6f6;
      color: #515151;
      font-size: 14px;
      font-style: normal;
      align-items: center;
      font-weight: 400;
      text-transform: capitalize;

      span {
        padding-right: 10px;
        position: relative;
        top: 4px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// .countryIcon {
//   border: 1px solid #f2f3f5;
// }

.skeleton {
  width: 85px;
  height: 32px;
  background: #f2f3f5;
  animation: loading 2s ease infinite;
}

@keyframes loading {
  0% {
    background-size: 300% 100%;
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 100% 50%;
  }

  100% {
    background-size: 300% 100%;
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 0 50%;
  }
}

.headerFindJob {
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: transform 0.3s ease;

  svg {
    transform: rotate(180deg);
  }
}

.headerFindJob:hover {
  svg {
    transform: rotate(0deg);
  }
}

.findJobDownMenu {
  :global {
    .ant-dropdown-menu {
      padding: 8px 12px;

      .ant-dropdown-menu-item {
        padding: 12px 8px !important;

        .ant-dropdown-menu-title-content {
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

.findJobDownMenu {
  .children {
    &_nav {
      display: flex;
      align-items: center;

      &_icon {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: center;
        margin-right: 12px;
        border-radius: 8px;
        background: #f5f7fb;
      }

      &_short {
        p {
          color: #7d7d7d;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      &_title {
        color: #121212;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }

      .childrenArrow {
        margin-left: auto;
        padding-left: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
        float: right;
      }

      .checkedIcon {
        display: none;
      }
    }
  }

  li {
    &:hover {
      background-color: #fcfcfc !important;

      .children {
        &_nav {
          .childrenArrow {
            opacity: 1;
          }
        }
      }
    }
  }
}

.remoteMenu {
  li:nth-of-type(1) {
    border-radius: 4px;
    background: #f4f8fe;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #2378e5;
      }
    }

    * {
      color: #2378e5;
    }

    .children {
      &_nav {
        &_icon {
          background: #2378e5;
        }
      }
    }
  }
}

.onsiteMenu {
  li:nth-of-type(2) {
    border-radius: 4px;
    color: #2378e5;
    background: #f4f8fe;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #2378e5;
      }
    }

    .children {
      &_nav {
        &_icon {
          background: #2378e5;
        }
      }
    }

    * {
      color: #2378e5;
    }
  }
}

.web3Menu {
  li:nth-of-type(3) {
    border-radius: 4px;
    color: #2378e5;
    background: #f4f8fe;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #2378e5;
      }
    }

    .children {
      &_nav {
        &_icon {
          background: #2378e5;
        }
      }
    }

    * {
      color: #2378e5;
    }
  }
}

.recommendedMenu {
  li:nth-of-type(4) {
    border-radius: 4px;
    color: #2378e5;
    background: #f4f8fe;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #2378e5;
      }
    }

    .children {
      &_nav {
        &_icon {
          background: #2378e5;
        }
      }
    }

    * {
      color: #2378e5;
    }
  }
}

.notificationIconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &[data-hasUnread='true'] {
    &::after {
      content: ' ';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #f75555;
      position: absolute;
      top: -3px;
      right: -3px;
    }
  }
}
